import * as React from "react"
import {
  DataGrid,
  plPL,
} from "@mui/x-data-grid"
import Pagination from "@mui/material/Pagination"
import Stack from "@mui/material/Stack"
import ChangeOrder from "./changeOrder"
import AddEditQuestNaczepa from "./AddEditQuestNaczepa"
import DeleteQuestNaczepa from './deleteQuestNaczepa'

import * as adres from "./adresy.js"

let port = adres.config.url

const lang = window.navigator.language
let jezyk
if (lang === "pl-PL") {
  jezyk = "polski"
} else {
  jezyk = "onucowy"
}

const newRow = {
  page: 1,
  seqNumber: null,
  id: "",
  question: {
    polski: "",
    onucowy: "",
  },
  type: "",
  value: "",
  required: true,
  image: "",
  opis: "",
  jednostka: "",
  options: {
    polski: "",
    onucowy: "",
  },
  ilosc: [],
  __v: 0,
}

let pytania = []

export default function PytaniaTruck() {
  const [rowsy, setRows] = React.useState([])
  const [columns, setColumns] = React.useState([])
  const [strona, setStrona] = React.useState(1)
  const [pageSize, setPageSize] = React.useState(0)
  const [maxPage, setMaxPage] = React.useState(10)
  const [openDial, setOpenDial] = React.useState(false)
  const handleOpenDial = () => setOpenDial(true)
  const handleCloseDial = () => setOpenDial(false)

  React.useEffect(() => {
    fetch(port+"/naczepa", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw response
      })
      .then((data) => {
        //add ref to array

        data.forEach((item) => {
          item.ref = React.createRef(item._id)
        })
        //sort by page
        data.sort((a, b) => a.page - b.page)
        setMaxPage(data[Object.keys(data).pop()].page)
        pytania = data
        const filtered = data.filter((item) => item.page === strona)
        setPageSize(filtered.length)
        const sorted = filtered.sort((a, b) => a.seqNumber - b.seqNumber)
        console.log(sorted)
        setRows(sorted)
      })
  }, [])

  const rozmiarStrony = (event, page) => {
    //filter array of objects by value of a key
    const stronka = pytania.filter((item) => item.page === page).length === 0 ? 1 : page
    const filtered = pytania.filter((item) => item.page === page).length === 0 ? pytania.filter((item) => item.page === 1) : pytania.filter((item) => item.page === page)
    setPageSize(filtered.length)
    //sort filtered by seqNumber
    const sorted = filtered.sort((a, b) => a.seqNumber - b.seqNumber)
    setStrona(stronka)
    setRows(sorted)
  }

  const rowReordering = (params, kierunek) => {
    const seqTemp = params.seqNumber + kierunek //seqNumber elementu, który ma zostać zastąpiony
    if (seqTemp < 1 || seqTemp > rowsy.length)
      return //jeśli seqNumber jest mniejszy od 1 lub większy od maxPage to nie wykonuj dalszych instrukcji
    else {
      const rowsTemp = [...rowsy] //kopia tablicy
      const index = rowsTemp.findIndex((item) => item.seqNumber === seqTemp) //znajduje index elementu, który ma zostać zastąpiony
      const temp = rowsTemp[index] //kopia elementu, który ma zostać zastąpiony
      rowsTemp[index] = rowsTemp[params.seqNumber - 1] //zastępuje element, który ma zostać zastąpiony
      rowsTemp[params.seqNumber - 1] = temp //zastępuje element zastępujący
      rowsTemp[index].seqNumber = seqTemp //nadaje nowy numer elementowi zastępującemu
      temp.seqNumber = rowsTemp[params.seqNumber - 1].seqNumber - kierunek //nadaje nowy numer elementowi zastąpionemu

      fetch(port+`/naczepa/${rowsTemp[index]._id}`, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ seqNumber: rowsTemp[index].seqNumber }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
          throw response
        })
        .then((data) => {
          //sort by page
        })
      fetch(port+`/naczepa/${temp._id}`, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ seqNumber: temp.seqNumber }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
          throw response
        })
        .then((data) => {
          //sort by page
        })
      setRows(rowsTemp)
    }
  }
  React.useEffect(() => {
    const kolumny = [
      {
        field: "question",
        headerName: "Pytanie",
        width: 300,
        renderCell: (params) => {
          return <div>{params.value.polski}</div>
        },
      },
      { field: "type", headerName: "Typ pola", width: 130 },
      { field: "page", headerName: "Strona", width: 130 },
      { field: "id" },
      { field: "Polski" },
      { field: "Rosyjski" },
      {
        field: "akcje",
        headerName: "Akcje",
        width: 300,
        renderCell: (params) => (
          <>
            <ChangeOrder
              row={params.row}
              kierunek="up"
              reordering={rowReordering}
            />
            <ChangeOrder
              row={params.row}
              kierunek="down"
              reordering={rowReordering}
            />
            {params.row.type !== "opony" ? (
              <AddEditQuestNaczepa
                row={params.row}
                add={addRow}
                edit={editRow}
                icon={"Edycja"}
              />
            ) : (
              <></>
            )}
            {params.row.type !== "opony" ? (
            <DeleteQuestNaczepa
                row={params.row}
                delete={deleteRow}
              />
            ) : (
              <></>
            )}
          </>
        ),
      },
    ]
    setColumns(kolumny)
  }, [rowsy])

  const editRow = (params) => {
    const rowsTemp = [...rowsy]
    const index = rowsTemp.findIndex((item) => item._id === params._id)
    rowsTemp[index] = params
    setRows(rowsTemp)
  }

  const addRow = (params) => {
    fetch(port+"/naczepa", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw response
      })
      .then((data) => {
        //add ref to array
        data.forEach((item) => {
          item.ref = React.createRef(item._id)
        })

        //sort by page
        data.sort((a, b) => a.page - b.page)
        setMaxPage(data[Object.keys(data).pop()].page)
        pytania = data
        rozmiarStrony(pytania, params.page)
        // const filtered = data.filter((item) => item.page === strona)
        // setPageSize(filtered.length)
        // const sorted = filtered.sort((a, b) => a.seqNumber - b.seqNumber)
        // setRows(sorted)
      })
  }

  const deleteRow = (params) => {
    fetch(port+"/naczepa", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw response
      })
      .then((data) => {
        //add ref to array
        data.forEach((item) => {
          item.ref = React.createRef(item._id)
        })
        //sort by page
        data.sort((a, b) => a.page - b.page)
        setMaxPage(data[Object.keys(data).pop()].page)
        pytania = data
        rozmiarStrony(pytania, params.page)
      })
  }

  return (
    <div style={{ position: "relative", height: "100%", width: "100%" }}>
      <Stack spacing={2}>
        <Pagination
          boundaryCount={maxPage}
          count={maxPage}
          variant="outlined"
          shape="rounded"
          size="large"
          page={strona}
          onChange={rozmiarStrony}
        />
      </Stack>
      <DataGrid
        localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
        getRowId={(row) => row._id}
        rows={rowsy}
        disableColumnMenu={true}
        disableColumnSelector={true}
        disableColumnFilter={true}
        columns={columns}
        hideFooterPagination={true}
        pageSize={pageSize}
        rowsPerPageOptions={[pageSize]}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
              Polski: false,
              Rosyjski: false,
            },
          },
        }}
      />
      <AddEditQuestNaczepa row={newRow} add={addRow} edit={editRow} icon={"Dodaj"} pytania={pytania}/>
    </div>
  )
}